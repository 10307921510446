<template>
  <div class="apply-wraps">
    <!-- <div class="apply-header">
      <p class="apply-title">申请单</p>
    </div> -->
    <van-loading
      class="load"
      color="#1989fa"
      size="50"
      v-if="isLoading"
    ></van-loading>
    <div
      class="apply-btn"
      click="shenqing"
      v-for="(item, index) in shenqingList"
      :key="index"
      @click="shenqingBtn(index)"
    >
      <img class="apply-icon-tag" src="../assets/images/tag@2x.png" />
      <div class="apply-text">
        <div class="time">{{ item.orderlist.AppDeptCode }}</div>
        <div>{{ item.updatedate }}</div>
      </div>

      <img class="apply-icon" src="../assets/images/xiangyou.png" />
    </div>
    <div class="empty" v-if="empty">
      <img
        src="https://hrp.weilaihos.com/uploads/20210121/98afe4a6068ee7b9cf59bbf25fb16377.png"
        alt=""
      />
      <text class="empty-text">暂无更多数据</text>
    </div>
  </div>
</template>

<script>
import { getFamilyOrder } from '../services/home'
export default {
  data() {
    return {
      active: 0,
      visitNumber: '',
      shenqingList: [],
      isLoading: true,
      empty: false
    }
  },
  async created() {
    await getFamilyOrder(this.$route.query.patientId).then(res => {
      const result = res.data.data.rows
      this.shenqingList = result
      result.forEach((e, i) => {
        res.data.data.rows[i].orderlist = JSON.parse(e.orderlist)
      })
      this.list = res.data.data.rows
      console.log(this.list)
    })
    if (this.shenqingList.length === 0) {
      this.empty = true
    }
    this.delay()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    delay() {
      setTimeout(() => {
        this.isLoading = false
      }, 300)
    },
    shenqingBtn(index) {
      setTimeout(() => {
        location.href =
          'https://wx.weilaihos.com/app/main?hi=24DWKAH0D383&user_id=' +
          this.$route.query.user_id +
          '&num=' +
          index +
          '&patpatientid=' +
          this.$route.query.patientId
      }, 1000)
    }
  }
}
</script>

<style scoped>
#app {
  min-height: auto !important;
}
.load {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.empty {
  height: 100vh;
  width: 100%;
  text-align: center;
  background: #ffffff;
  color: #909399;
}
.empty img {
  display: block;
  width: 375px;
  height: 293px;
}
.empty .empty-text {
  width: 100%;
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  line-height: 25px;
  position: absolute;
  top: 230px;
  left: 0px;
  text-align: center;
}

.apply-wraps {
  background: #f9f9f9;
  height: 100vh;
  width: 100%;
  overflow-y: auto;
}

.apply-header {
  height: 46px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #5ca5ff;
  font-size: 16px;
  z-index: 100;
}

.apply-btn {
  box-sizing: border-box;
  width: 92%;
  height: 60px;
  color: #333;
  background: #ffffff;
  box-shadow: 0px 1px 2px 0px #eeeeee;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px auto;
  padding: 0 2% 0 10%;
  position: relative;
}

.apply-text {
  display: flex;
  flex: 1;
  text-align: center;
  font-size: 16px;
  justify-content: space-between;
  align-items: center;
}
.apply-text .time {
  width: 144px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.apply-icon-tag {
  position: absolute;
  top: 10px;
  left: 0;
  width: 22px;
  height: 14px;
}

.apply-icon {
  height: 16px;
  width: 16px;
}
</style>
